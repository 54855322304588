exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abrid-surf-camp-accommodation-js": () => import("./../../../src/pages/abrid-surf-camp-accommodation.js" /* webpackChunkName: "component---src-pages-abrid-surf-camp-accommodation-js" */),
  "component---src-pages-abrid-surf-camp-booking-js": () => import("./../../../src/pages/abrid-surf-camp-booking.js" /* webpackChunkName: "component---src-pages-abrid-surf-camp-booking-js" */),
  "component---src-pages-abrid-surf-camp-js": () => import("./../../../src/pages/abrid-surf-camp.js" /* webpackChunkName: "component---src-pages-abrid-surf-camp-js" */),
  "component---src-pages-abrid-surf-camp-packages-js": () => import("./../../../src/pages/abrid-surf-camp-packages.js" /* webpackChunkName: "component---src-pages-abrid-surf-camp-packages-js" */),
  "component---src-pages-abrid-surf-coaching-js": () => import("./../../../src/pages/abrid-surf-coaching.js" /* webpackChunkName: "component---src-pages-abrid-surf-coaching-js" */),
  "component---src-pages-abrid-surf-guiding-js": () => import("./../../../src/pages/abrid-surf-guiding.js" /* webpackChunkName: "component---src-pages-abrid-surf-guiding-js" */),
  "component---src-pages-abrid-surf-school-js": () => import("./../../../src/pages/abrid-surf-school.js" /* webpackChunkName: "component---src-pages-abrid-surf-school-js" */),
  "component---src-pages-abrid-surf-yoga-js": () => import("./../../../src/pages/abrid-surf-yoga.js" /* webpackChunkName: "component---src-pages-abrid-surf-yoga-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

